<template>
  <div class="fission-container">
    <div class="cover">
      <img src="@/assets/images/course/course-fission.png" alt="" />
      <div class="btn" @click="$router.push('/course/fission')">
        <img src="@/assets/images/course/fission-btn.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseFission'
}
</script>

<style lang="less" scoped>
.fission-container {
  margin-top: 97px;
  position: relative;
  .cover {
    width: 335px;
    height: 491px;
    margin: 0 auto;
  }
  .btn {
    width: 160px;
    height: 223px;
    position: absolute;
    top: 268px;
    right: 20px;
  }
}
</style>
