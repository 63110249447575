<template>
  <div class="land-container">
    <div class="cover">
      <van-image
        width="100%"
        height="100%"
        lazy-load
        :src="require('@/assets/images/course/course-land.png')"
      />
      <div class="btn" @click="$router.push('/course/land')">
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/course/land-btn.png')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseLand'
}
</script>

<style lang="less" scoped>
.land-container {
  margin-top: 34px;
  position: relative;
  .cover {
    width: 335px;
    height: 469px;
    margin: 0 auto;
  }
  .btn {
    width: 190px;
    height: 46px;
    position: absolute;
    top: 461px;
    right: 20px;
  }
}
</style>
