<template>
  <div class="fission-container">
    <div class="cover">
      <van-image
        width="100%"
        height="100%"
        lazy-load
        :src="require('@/assets/images/course/course-member.png')"
      />

      <div class="btn" @click="$router.push('/course/member')">
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/course/member-btn.png')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseMember'
}
</script>

<style lang="less" scoped>
.fission-container {
  margin-top: 54px;
  position: relative;
  .cover {
    width: 335px;
    height: 425px;
    margin: 0 auto;
  }
  .btn {
    width: 47px;
    height: 181px;
    position: absolute;
    top: 0px;
    right: 20.5px;
  }
}
</style>
