<template>
  <div class="course-container">
    <!-- navbar -->
    <QbNavbar title="泉邦课程"></QbNavbar>
    <!-- title -->
    <qb-title title="泉邦课程" subtitle="Quanbang Group Product"></qb-title>
    <!-- land -->
    <CourseLand></CourseLand>
    <!-- fission -->
    <CourseFission></CourseFission>
    <!-- sale -->
    <CourseSale></CourseSale>
    <!-- member -->
    <CourseMember></CourseMember>
    <!-- brand-advantage -->
    <CourseBrandAdvantage></CourseBrandAdvantage>
  </div>
</template>

<script>
import CourseLand from './components/course-land.vue'
import CourseFission from './components/course-fission.vue'
import CourseSale from './components/course-sale.vue'
import CourseMember from './components/course-member.vue'
import CourseBrandAdvantage from './components/course-brand-advantage.vue'
export default {
  name: '',
  data() {
    return {}
  },
  components: {
    CourseLand,
    CourseFission,
    CourseSale,
    CourseMember,
    CourseBrandAdvantage
  }
}
</script>

<style lang="less" scoped></style>
